export const RELATED_FILTERS: any = {
  seller: "retailer",
  retailer: "seller",
  address: "city",
  city: "address",
};

export const RELATED_SERP_FILTERS: any = {
  keyword: "serpType",
  serpType: "keyword",
  address: "city",
  city: "address",
};
