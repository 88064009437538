import login from "./login/ru.json";
import availability from "./availability/ru.json";
import pricesDiscounts from "./prices-discounts/ru.json";
import content from "./content/ru.json";
import productList from "./product-list/ru.json";
import masterList from "./master-list/ru.json";
import reviewsAndRating from "./reviews-and-rating/ru.json";
import searchPosition from "./search-position/ru.json";
import plannedReports from "./planned-reports/ru.json";
import filterGroups from "./filter-groups/ru.json";
import dataCopy from "./data-copy/ru.json";

export const pages = {
  pages: {
    ...login,
    ...availability,
    ...pricesDiscounts,
    ...content,
    ...productList,
    ...masterList,
    ...reviewsAndRating,
    ...searchPosition,
    ...plannedReports,
    ...filterGroups,
    ...dataCopy,
  },
};
