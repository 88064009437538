import { useQuery } from "@apollo/client";
import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";

import { filters, serpFilters, reviewsFilters } from "@/api/filters";
import { useAppDispatch } from "@/store";
import { setFiltersData } from "@/store/filters";
import { FilterIcons } from "@/consts/filter-icons";
// import { getFiltersFromDynamicFilters } from "@/modules/shared/utils";

import type { TRequest, TResponse } from "@/api/filters/types";
import type { TState } from "@/store/types";

const useFilters = (
  request?: TRequest,
  isDispatch?: boolean,
  requestName = "getFilters",
  filtersList?: any[],
  clientId?: number | null
) => {
  const dispatch = useAppDispatch();
  const { date } = useSelector((state: TState) => state.filters);

  const dates = {
    from: date.startDate?.format("YYYY-MM-DD") || "",
    to: date.endDate?.format("YYYY-MM-DD") || "",
  };

  const defaultIds = [
    "country",
    "city",
    "address",
    "retailer",
    "brand",
    "seller",
    "category",
    "skuId",
    "productName",
    "availability",
  ];

  const defaultSerpIds = [
    "country",
    "city",
    "retailer",
    "brand",
    "rawBrand",
    "serpType",
    "keyword",
    // "rawCategory",
    "productName",
    "skuId",
  ];

  const defaultReviewsIds = [
    "country",
    "city",
    "retailer",
    "brand",
    "rawBrand",
    "serpType",
    "keyword",
    // "rawCategory",
    "skuId",
  ];

  const { getFilters } = filters;
  const { getSerpFilters } = serpFilters;
  const { getReviewFilters } = reviewsFilters;

  const intFiltersList = filtersList || [];

  if (requestName === "getReviewFilters") {
    intFiltersList.push({ id: "reviewType", values: ["review"] });
  }

  if (requestName === "getQuestionFilters") {
    intFiltersList.push({ id: "reviewType", values: ["question"] });
  }

  const {
    data: response,
    loading,
    error,
  } = useQuery<TResponse>(
    requestName === "getFilters"
      ? getFilters
      : requestName === "getReviewFilters" ||
        requestName === "getQuestionFilters"
      ? getReviewFilters
      : getSerpFilters,
    {
      variables: {
        dates,
        ids:
          request?.attributes ||
          (requestName === "getFilters"
            ? defaultIds
            : requestName === "getReviewFilters" ||
              requestName === "getQuestionFilters"
            ? defaultReviewsIds
            : defaultSerpIds),
        filters: intFiltersList,
        clientId,
      },
      fetchPolicy: "no-cache",
    }
  );

  const data = useMemo(
    () =>
      response
        ? {
            filters: response.filters.map((filter) => ({
              ...filter,
              icon: FilterIcons[filter.id],
            })),
          }
        : undefined,
    [response]
  );

  useEffect(() => {
    if (isDispatch) dispatch(setFiltersData(data));
  }, [data]);

  return {
    data,
    loading,
    error,
  };
};

export { useFilters };
