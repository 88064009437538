import login from "./login/en.json";
import availability from "./availability/en.json";
import pricesDiscounts from "./prices-discounts/en.json";
import content from "./content/en.json";
import productList from "./product-list/en.json";
import masterList from "./master-list/en.json";
import reviewsAndRating from "./reviews-and-rating/en.json";
import searchPosition from "./search-position/en.json";
import plannedReports from "./planned-reports/en.json";
import filterGroups from "./filter-groups/en.json";
import dataCopy from "./data-copy/en.json";

export const pages = {
  pages: {
    ...login,
    ...availability,
    ...pricesDiscounts,
    ...content,
    ...productList,
    ...masterList,
    ...reviewsAndRating,
    ...searchPosition,
    ...plannedReports,
    ...filterGroups,
    ...dataCopy,
  },
};
