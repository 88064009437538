import React, { Suspense, useEffect, useMemo } from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import loadable from "@loadable/component";
import { jwtDecode } from "jwt-decode";
import { cloneDeep } from "lodash";

import { Loading } from "@/modules/shared/atoms";
import { routes, routesMap } from "@/app/consts";

import type { TRoute, TToken } from "@/app/types";
import type { FC } from "react";

const getUserType = () => {
  const token = localStorage.getItem("token");
  let userType = "user";

  if (token) {
    const decodedToken: TToken = jwtDecode(token);

    if (decodedToken.roles.indexOf("ROLE_MANAGER") !== -1) {
      userType = "manager";
    } else if (decodedToken.roles.indexOf("ROLE_SUPER_ADMIN") !== -1) {
      userType = "admin";
    }
  }

  return userType;
};

const NoMatch: FC = () => {
  const userType = getUserType();

  const history = useHistory();

  useEffect(() => {
    if (localStorage.getItem("token")) {
      history.replace(
        userType === "manager"
          ? "/master-list/master"
          : userType === "admin"
          ? "/data-copy/data-copy-list"
          : "/availability/general-information"
      );
    } else {
      history.replace("/auth/login");
    }
  }, []);

  return null;
};

const Routes: FC = () => {
  const userType = getUserType();

  const routesFilterFunction = (route: TRoute) =>
    route.permission === userType || !route.permission;

  const filteredRoutes = useMemo(
    () => cloneDeep(routes).filter(routesFilterFunction),
    [routesFilterFunction]
  );

  return (
    <Switch>
      {filteredRoutes.map((route) => {
        if (route.parentKey) {
          const parentRoute = routesMap[route.parentKey];
          const ParentRouteComponent = loadable(
            () =>
              import(/* webpackPrefetch: true */ `../${parentRoute.component}`)
          );
          const RouteComponent = loadable(
            () => import(/* webpackPrefetch: true */ `../${route.component}`)
          );

          return (
            <Route key={route.key} exact path={route.path}>
              <Suspense fallback={<Loading />}>
                <ParentRouteComponent
                  customFiltersTypes={route.customFiltersTypes}
                  hideHeaderControls={route.hideHeaderControls}
                  title={route.title}
                  withSearch={route.withSearch}
                  withoutFilters={route.withoutFilters}
                  withoutLists={route.withoutLists}
                >
                  <RouteComponent />
                </ParentRouteComponent>
              </Suspense>
            </Route>
          );
        }
        const RouteComponent = loadable(
          () => import(/* webpackPrefetch: true */ `../${route.component}`)
        );

        return (
          <Route key={route.key} exact path={route.path}>
            <Suspense fallback={<Loading />}>
              <RouteComponent />
            </Suspense>
          </Route>
        );
      })}
      <Route path="*">
        <NoMatch />
      </Route>
    </Switch>
  );
};

export default Routes;
